<!--
  用户管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
			<el-card class="box-card">
			 <div slot="header" class="card-header">
					<h3 class="card-title titleCont">
						<span class="spIcon"></span>
						{{Title}}
					</h3>
				</div>
				<div class="card-body">
					<el-row :gutter="24" style="margin-top: 20px;">
						<el-col :span="11">
							<el-form-item label="用户名" prop="userName">
								<el-input show-word-limit auto-complete="new-userName" maxlength="20" v-model="ruleForm.userName" placeholder="请输入用户名,作为登录的唯一标识,长度不能超过20个字符" class="formControl"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="姓名" prop="name">
								<el-input show-word-limit auto-complete="new-name" maxlength="20" v-model="ruleForm.name" placeholder="请输入姓名,长度不能超过20个字符" class="formControl"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="11" v-if="ruleForm.userId == null">
							<el-form-item prop="password">
								<label slot="label"><span style="color:#f66c6c">* </span>输入密码</label>
								<el-input show-word-limit auto-complete="new-password" show-password maxlength="16" v-model.trim="ruleForm.password" placeholder="请输入密码,密码为8-16位，数字和字母组成，不能是纯数字或纯字母" class="formControl"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11" v-if="ruleForm.userId == null">
							<el-form-item prop="againPassword">
								<label slot="label"><span style="color:#f66c6c">* </span>确认密码</label>
								<el-input show-word-limit auto-complete="new-againPassword" show-password maxlength="16" v-model.trim="ruleForm.againPassword" placeholder="请确认密码,密码为8-16位，数字和字母组成，不能是纯数字或纯字母" class="formControl"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="手机号码">
								<el-input show-word-limit  v-model="ruleForm.mobile" placeholder="请输入手机号码" class="formControl"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="电子邮箱">
								<el-input show-word-limit v-model="ruleForm.email" placeholder="请输入电子邮箱" class="formControl"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="启用">
								<el-switch
								  v-model="ruleForm.enable">
								</el-switch>
							</el-form-item>
						</el-col>
						<el-col :span="11">
							<el-form-item label="类型">
								<el-select v-model="ruleForm.type" placeholder="请选择" class="formControl" style="width:100% !important;">
										<el-option
											v-for="item in userTypes"
											:key="item.Id"
											:label="item.Title"
											:value="item.Id">
										</el-option>
									</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</div>

			</el-card>
			<div class="row" style="margin-top: 10px;">
					<div class="col-12">
						<el-button  icon="el-icon-back" @click="onBack()">返回</el-button>
						<el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
						<el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
					</div>
			</div>
		</el-form>
  </div>
</template>

<script>
  export default{
    components:{
    },
    data()
		{
      return{
				userTypes:[],
				ruleForm: //表单数据
				{
					userId:null,//用户主键，新增时为null
					userName:null,//用户名
					password:null,//密码
					name:null,//姓名
					enable:true,//启用
          mobile:null,
          email:null,
					enable:true,
					type:1,
				},
        userIdentitysReset:null,
				userRolesReset:null,
				ruleFormReset:null,
				Title:"新增账号",
				fullscreenLoading:false,
				rules: {
					name: [
						{ required: true, message: '姓名不能为空', trigger: 'change' },
						{ max: 20,message: '姓名不能超过20个字符', trigger: 'change' },
					],
					userName: [
						{ required: true, message: '用户名不能为空', trigger: 'change' },
						{ max: 20,message: '用户名不能超过20个字符', trigger: 'change' },
					],
					againPassword:[
						{
							validator: (rule, value, callback) => {
								let that = this;
								if(that.ruleForm.userId == null)
								{
									if(that.ruleForm.againPassword==null || that.ruleForm.againPassword.length<=0)
									{
										callback(new Error("请确认密码！"));
									}
									else
									{
										if(that.ruleForm.password != value)
										{
											callback(new Error("两次密码不一致！"));
										}
									}
								}
								callback();
							},
							trigger: 'change'
						}
					],
					password: [
						{
							validator: (rule, value, callback) => {
								let that = this;
								if(that.ruleForm.userId == null)
								{
									if(that.ruleForm.password==null || that.ruleForm.password.length<=0)
									{
										callback(new Error("请输入密码！"));
									}
									else{
										var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
										if (value!=0 && !reg.exec(value)) {
											callback(new Error("密码为8-16位，数字和字母组成，不能是纯数字或纯字母！"));
										}
									}
								}
								callback();
							},
							trigger: 'change'
						}
					],
				},
      };
    },
		created()
		{
			let that = this;
			let id = that.$route.params.Id;
			var detailUrl = null;
			if(id!=null && typeof(id)!="undefined" && id!="")
			{
				that.Title = "编辑账号";
				that.ruleForm.reasonId = id;
				detailUrl = "/api/admin/sysUser/" + id;
			}
			that.fullscreenLoading = true;
			ajaxGet(that, "/api/admin/enum/getUserIdentity", null, function (r) {
				that.userTypes = r;
				ajaxGet(that, detailUrl, null, function (r) {
					if(r != null)
					{
						that.ruleForm = r;
					}
					that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
					that.fullscreenLoading = false;
				
				});
			})
		},
		methods:{
						onSave()
						{
							let that = this;
							that.$refs["ruleForm"].validate(valid => {
									if (valid) {
			              //新增
										if(that.ruleForm.userId == null)
										{
											that.fullscreenLoading = true;
											// that.ruleForm.password = that.$md5(that.ruleForm.password);
			                var data= that.ruleForm;
											ajaxPost(that,"/api/admin/sysUser",data,function(r){
												that.fullscreenLoading = false;
			                  that.onBack();
											});
										}
										else{
											that.fullscreenLoading = true;
											ajaxPut(that,"/api/admin/sysUser/" + that.ruleForm.userId,that.ruleForm,function(r){
												that.fullscreenLoading = false;
												that.onBack();
											});
										}
									}
									else {
											setTimeout(() => {
													var isError = document.getElementsByClassName("is-error");
													isError[0].querySelector('input').focus();
											}, 1)
											return false;
									}
							});
						},
						onReset()
						{
							let that = this;
							that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
							try
							{
								that.$refs["ruleForm"].resetFields();
							} catch{}
						},
						onBack()
						{
							let that = this;
							back(that);
						},
		},
  };
</script>

<style scoped="scoped">
  label{
    margin-bottom: 0;
  }
  .inputGroupCont{
    padding-top: 15px;
  }
  .inputGroupCont .inputList{
    padding-bottom: 10px;
  }

	.spIcon {
	    width: 5px;
	    height: 20px;
	    border-radius: 5px;
	    background-image: linear-gradient(#4545FF, #2D8EFF);
	}

	.spTitle{
		float:left;
		margin-left: 20px;
		color: #323E56;
		letter-spacing: 2px;
		font-size: 16px;
		margin-top: -3px;
	}

	.wMax {
	    width:100% !important;
	}
</style>
